import styled from "styled-components"

export const Titulo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5da6ad;
  font-family: "Pacifico", cursive;

  h2 {
    font-size: 40px;
    font-weight: normal;
    margin: 20px 0 10px 0;
  }

  p {
    font-size: 25px;
    margin-top: 50px;
  }

  hr {
    width: 10%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(93, 166, 173, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
`

export const ContatoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-right: 350px;

  img {
    width: 500px;
    border-radius: 5px;
  }

  @media (max-width: 425px) {
    margin: 0;

    img {
      width: 250px;
    }
  }
`
export const FormContainer = styled.div`
  position: relative;
  top: -100px;
  right: -350px;
  background-color: rgb(202, 224, 218, 0.45);
  width: 30vw;
  height: 70vh;
  border-radius: 5px;
  padding: 40px;

  @media (max-width: 425px) {
    top: 0;
    right: 0;
    width: 100%;
    padding: 20px;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: rgba(93, 166, 173, 0.7);
  border-radius: 5px;

  h1 {
    color: #fff;
    font-size: 40px;
    margin-top: 50px;
    font-weight: normal;
  }

  h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
  }

  a {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    font-weight: bold;
    color: #000;
  }

  a:hover {
    color:#FF924B;
  }

  @media (max-width: 425px) {
    h1 {
      font-size: 25px;
    }
  }
`
